import { useEffect, useState } from "react";

import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { List, X } from "react-bootstrap-icons";

import Navbar from "./navbar";

function Header() {
    const [show, setShow] = useState(false);
    const router = useRouter();

    const [background, setBackground] = useState(true);

    useEffect(() => {
        const listenScrollEvent = () => {
            if (window.scrollY < 80) {
                return setBackground(true);
            } else if (window.scrollY > 80) {
                return setBackground(false);
            }
        };
        if (router.pathname === "/") {
            window.addEventListener("scroll", listenScrollEvent);
            return () =>
                window.removeEventListener("scroll", listenScrollEvent);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <header
            className={`app-header ${background && router.pathname === "/" ? "bgGradient" : ""}`}
        // style={
        //     [APP_ROUTES.explore, "/explore/[searchId]"].includes(router.pathname) ? {
        //         paddingInline: "32px",
        //     } : {}}
        >
            {/* <div
                className="container"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}> */}
            < div className="logo" >
                <Link href="/">
                    {/* <img src="/images/TGI-logo-2021.png" alt="logo_image" /> */}
                    <Image
                        src="/images/TGI-logo-2021.png"
                        alt="logo_image"
                        width={131}
                        height={59}
                        loading="lazy"
                    />
                </Link>
            </div >

            <div className="rightNavigationWrap">
                <div className={`headerTabsNavigation ${show ? "active" : ""}`}>
                    <Navbar />
                </div>
            </div>
            {/* {
                    ![APP_ROUTES.explore, "/explore/[searchId]"].includes(router.pathname)
                    && <div className={`${styles["hero-section-button"]} actionBtns `} style={{ margin: 0 }}>
                        <Link href={APP_ROUTES.explore}>
                            <Button
                                color="blue">
                                Explore the data<FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                        </Link>
                    </div>
                } */}
            <button className='menuBtn' aria-label="menu" onClick={() => setShow(!show)}>
                {!show ? <List /> : <X color='#FD7278' size="40" />}
            </button>
            {/* </div> */}
        </header >
    );
}

export default Header;

import React, { useState } from "react";

import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import Image from "next/image";
import Link from "next/link";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";

import { ContactTypeEnum } from "@/enum/common";
import { handleAxiosError } from "@/helpers/common";
import { saveContactService } from "@/services/contactService";

import styles from "./footer.module.scss";

const schema = yup
    .object({
        email: yup.string().email("Please enter a valid email.").required("This is a required field."),
        type: yup.string().required("This is a required field."),
    })
    .required();

function Footer() {
    const [isLoading, setIsLoading] = useState(false);

    const { control, handleSubmit, reset }
        = useForm({
            mode: "all",
            resolver: yupResolver(schema),
            defaultValues: {
                email: "",
                type: ContactTypeEnum.subscribe,

            },
        });

    const onSubmit = async (data: any) => {
        try {
            setIsLoading(true);

            const formData = {
                ...data,
            };
            const res = await saveContactService(formData);
            setIsLoading(false);
            if (res.data.message) {
                toast.success(<div className={styles["subscribe-input-cont-toast-success"]}>
                    <FontAwesomeIcon icon={faEnvelope} />
                    {res.data.message}
                </div>
                , { position: "bottom-left" });
            }
            reset();
        } catch (error) {
            setIsLoading(false);
            handleAxiosError(error, toast);
        }
    };
    return (
        <div className={styles["cont"]}>
            <div className={"grid-wrapper"}>
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                        <div className={styles["subscribe"]}>
                            <div className={styles["subscribe-title"]}>
                                Subscribe
                            </div>
                            <div className={styles["subscribe-summary"]}>
                                Sign up to the newsletter to keep up to date with all the latest from the Gender Index
                            </div>
                            <div className={styles["subscribe-input-cont"]}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field, fieldState }) =>
                                            <>
                                                <input type="email" placeholder={"Email"} {...field} />
                                                <button type="submit" disabled={isLoading}>
                                                    <span>Submit</span>
                                                    {
                                                        isLoading
                                                        && <Image
                                                            width={16}
                                                            height={16}
                                                            src="/images/loading-gif.gif"
                                                            alt="loader_image"
                                                            loading="lazy" />
                                                    }
                                                </button>
                                                {
                                                    fieldState.error
                                                    && <div className={styles["subscribe-input-cont-error"]}>
                                                        {fieldState.error?.message}
                                                    </div>
                                                }
                                            </>
                                        }
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-2 col-xl-2">
                        <div className={styles["links"]}>
                            <div className="row">
                                <div className="col-12 col-sm-4 col-md-4 col-lg-12 col-xl-12">
                                    <Link href="/supporters">Supporters</Link>
                                </div>
                                <div className="col-12 col-sm-4 col-md-4 col-lg-12 col-xl-12">
                                    <Link href="/who-we-are">Who we are</Link>
                                </div>
                                <div className="col-12 col-sm-4 col-md-4 col-lg-12 col-xl-12">
                                    <Link href="/privacy-policy">Cookie and legals</Link>
                                </div>
                                <div className="col-12 col-lg-12 col-xl-12">
                                    <Link href="/faq">FAQ</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-2 col-xl-2">
                        <div className="row">
                            <div className="col-12 col-sm-4 col-md-4 col-lg-12 col-xl-12">
                                <Link href="https://www.linkedin.com/company/the-gender-index" target={"_blank"}>
                                    Linkedin
                                </Link>

                            </div>
                            <div className="col-12 col-sm-4 col-md-4 col-lg-12 col-xl-12">
                                <Link href="https://www.facebook.com/TheGenderIndex" target={"_blank"}>
                                    Facebook
                                </Link>

                            </div>
                            <div className="col-12 col-sm-4 col-md-4 col-lg-12">
                                <Link href="https://twitter.com/TheGenderIndex" target={"_blank"}>
                                    Twitter
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-2 col-xl-2">
                        <div className={styles["powered-by"]}>
                            <div className={styles["powered-by-title"]}>Powered by</div>
                            <a href="https://www.mnai.tech/" target="_blank" rel="noopener noreferrer">
                                <Image
                                    src="/images/mnAi.png"
                                    width={234}
                                    height={61}
                                    alt="mnAi"
                                    loading="lazy"
                                />
                            </a>
                            <div className={styles["powered-by-gender"]}>{new Date().getFullYear()} The Gender Index</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;

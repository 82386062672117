import Footer from "./footer/Footer";
import Header from "./header";

interface IRootLayout {
    children: React.ReactNode;
    isExplorePage?: boolean;
    allowContainer?: boolean;
    hideFooter?: boolean;
    hideHeader?: boolean;
}
export default function RootLayout({
    children,
    isExplorePage,
    allowContainer = true,
    hideFooter = false,
}: IRootLayout) {
    return (
        <div className={isExplorePage ? "noFooter" : ""}>
            {/* {!hideHeader && <Header />} */}
            <Header />
            <div className={allowContainer ? "homeContainer" : ""}>
                {children}
            </div>
            {
                !hideFooter
                && <Footer />
            }
        </div>
    );
}

import { useState } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import { APP_ROUTES } from "@/constants/routes";

function Navbar() {
    const router = useRouter();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [linkList, setLinkList] = useState<any[]>([
        {
            name: "Home",
            href: "/",
            pathname: "/",
        },
        {
            name: "Explore the data",
            href: APP_ROUTES.explore,
            pathname: "/explore",
            shouldIncludes: true,
        },
        {
            name: "Reports",
            href: "/reports",
            pathname: "/reports",
            shouldIncludes: true,
        },
        {
            name: "Partners and sponsors",
            href: "/headline-partners",
            pathname: "/headline-partners",
            shouldIncludes: true,
            urls: ["/headline-partners", "/headline-sponsors", "/associates", "/headline-sponsors/goldman-sachs", "/headline-sponsors/amazon-web-services", "/headline-sponsors/shoosmiths"],
        },
        {
            name: "Stories",
            href: "/stories",
            pathname: "/stories",
        },
        {
            name: "News",
            href: "/news",
            pathname: "/news",
        },
        {
            name: "Contact",
            href: "/contact",
            pathname: "/contact",
        },
    ]);

    // useEffect(() => {
    //     const LINKS: any[] = [
    //         {
    //             name: "Home",
    //             href: "/",
    //             pathname: "/",
    //         },
    //         // {
    //         //     name: "The Gender Index",
    //         //     href: APP_ROUTES.explore,
    //         //     pathname: "/explore/[searchId]",
    //         // },
    //         // {
    //         //     name: "The Gender Index",
    //         //     href: APP_ROUTES.explore,
    //         //     pathname: "/explore",
    //         //     shouldIncludes: true,
    //         // },
    //         {
    //             name: "Explore the data",
    //             href: APP_ROUTES.explore,
    //             pathname: "/explore",
    //             shouldIncludes: true,
    //         },
    //         {
    //             name: "Reports",
    //             href: "/reports",
    //             pathname: "/reports",
    //             shouldIncludes: true,
    //         },
    //         {
    //             name: "Partners and sponsors",
    //             href: "/headline-partners",
    //             pathname: "/headline-partners",
    //             shouldIncludes: true,
    //             urls: ["/headline-partners", "/headline-sponsors", "/associates", "/headline-sponsors/goldman-sachs", "/headline-sponsors/amazon-web-services", "/headline-sponsors/shoosmiths"],
    //         },
    //         {
    //             name: "Stories",
    //             href: "/stories",
    //             pathname: "/stories",
    //         },
    //         {
    //             name: "News",
    //             href: "/news",
    //             pathname: "/news",
    //         },
    //         {
    //             name: "Contact",
    //             href: "/contact",
    //             pathname: "/contact",
    //         },
    //     ];
    //     // if(router.pathname === APP_ROUTES.explore){
    //     //     const element = {
    //     //         name: "Explore the data",
    //     //         href: APP_ROUTES.explore,
    //     //         pathname: "/explore",
    //     //         shouldIncludes: true,
    //     //     };
    //     //     LINKS.splice(1, 0, element);
    //     // }
    //     setLinkList(LINKS);
    // }, [router.pathname]);

    return (
        <nav>
            <ul className='commonList'>
                {
                    linkList.map(i => (
                        <li className={router.pathname === i.pathname
                            || (i.shouldIncludes && router.pathname.includes(i.pathname)) || (i?.urls && i?.urls.includes(router.pathname)) ? "active" : ""} key={i.name}>
                            <Link href={i.href}>{i.name}</Link>
                        </li>
                    ))
                }
            </ul>
        </nav>
    );
}

export default Navbar;

import { API_ROUTES } from "@/constants";
import { IResponse } from "@/types/common";

import { ApiService } from "./ApiService";

async function saveContactService(formData: any): Promise<IResponse<any>> {
    return await ApiService.post(API_ROUTES.SAVE_CONTACT, formData);
}
export {
    saveContactService,
};

const CHART_COLORS = {
    PURPLE: "#012844",
    RED: "#D46B6A",
    BLUE: "#4CA8B5",
    YELLOW: "#E8AE4E",
    PURPLE1: "#656394",
    TEAL: "#00A7B7",

};

const COLORS = {
    PURPLE: "#012844",
    RED: "#D46B6A",
    BLUE: "#4CA8B5",
    YELLOW: "#E8AE4E",
    PURPLE1: "#656394",
    BLUE1: "#427D9C",
    GREEN: "#528C6C",
    BLUE2: "#6BAEAE",
    YELLO1: "#D7833D",
    ORANGE: "#C26255",
    MAGENTA: "#905178",
    ORANGE1: "#FD7278",
    BLUE3: "#00A7B7",
    VOILET: "#012348",

    WHITE: "#FFFFFF",
    GREY: "#EDF0F1",
};

const getChartPallete = () => Object.values(CHART_COLORS);

const DEFAULT_FILTER_ID = "a8f76e38b14e8858d78430d23b8015dca6b3e649";

const getApiUrl = () => {
    return process.env.NEXT_PUBLIC_API_URL || "";
};

const getWebsiteUrl = () => {
    return process.env.NEXT_PUBLIC_URL || "";
};

const API_ROUTES = {
    SAVE_FILTERS: "/search",
    GET_SEARCH_DATA: "/get-filter",
    SAVE_CONTACT: "/save-enquiry",
    GET_HOMEPAGE_DATA: "/document",
    RSVP_EVENT:"/event-response",
};

export {
    CHART_COLORS,
    getChartPallete,
    DEFAULT_FILTER_ID,
    getApiUrl,
    getWebsiteUrl,
    API_ROUTES,
    COLORS,
};

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { getApiUrl } from "@/constants";

class ApiService {
    static axiosInstance: AxiosInstance = axios.create({
        baseURL: getApiUrl(),
    });

    public constructor() {
        ApiService.axiosInstance.interceptors.request.use((config) => {
            return config;
        }, error => {
            return Promise.reject(error);
        });

        ApiService.axiosInstance.interceptors.response.use(
            function (response) {
                // console.log(response);
                return response;
            },
            error => {
                return Promise.reject(error);
            }
        );
    }

    public static get<T, R = AxiosResponse<T>>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.axiosInstance(url, config);
    }

    public static post<T, B, R = AxiosResponse<T>>(
        url: string,
        data?: B,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.axiosInstance.post(url, data, config);
    }

    public static put<T, B, R = AxiosResponse<T>>(
        url: string,
        data?: B,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.axiosInstance.put(url, data, config);
    }

}

export {
    ApiService,
};
